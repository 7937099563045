<div class="container">
  <h2 *ngIf="!updatingMenu" class="section-title text-center">Create</h2>
  <div class="row">

    <div class="col">

      <div class="text">
        <a [routerLink]="['../']" class="btn btn-primary m-0" role="button"><i class="fa fa-list"
            aria-hidden="true"></i>
          Main
          Menu</a>

      </div>
    </div>

  </div>
  <div class="row">
    <div class="col">
      <!-- <form #menuSelectForm="ngForm" method="post" *ngIf="!updatingMenu; else updatingBlock">
        <fieldset class="form-group">
          <div class="form-group">
            <label for="menuSelect">Select an Outlet</label>
            <select id="menuSelect" class="form-control" [(ngModel)]="selectedEntityKey"
              (ngModelChange)="selectEntity($event)" name=" selectedEntity" required class="form-control">

              <option *ngFor="let menuEntity of visibleEntitiesList | keyvalue" class="form-control"
                [value]="menuEntity.key">
                {{menuEntity.value.path}}</option>
            </select>
          </div>
          
        </fieldset>
      </form> -->

      <ngx-select-dropdown *ngIf="!updatingMenu; else updatingBlock" (change)="selectEntityFromSearchDropdown($event)"
        [multiple]="false" [(ngModel)]="selectedEntityKey" [config]="config" [options]="visibleEntitiesList">
      </ngx-select-dropdown>

      <ng-template #updatingBlock>
        <h3 class="text-center">Updating {{shortcode}}</h3>
        <p class="text-center" *ngIf="selectedEntity">{{selectedEntity.entityName}}</p>
      </ng-template>

    </div>
  </div>

  <div class="row">
    <div class="col"></div>
    <div class="col-xs-12 col-sm-6 col-md-4">
      <div class="frontside">
        <div class="text-center" [hidden]="!selectedEntity">
          <label [hidden]="inputURL" class="btn btn-primary file-input-btn">
            <strong>Upload file</strong>
            <input type="file" hidden name="file" type="file" (change)="fileChangeEvent($event)">
          </label>
          <h5 [hidden]="file || inputURL">or</h5>
          <input [hidden]="file" type="url" id="inputURLField" name="inputURLField" [(ngModel)]="inputURL"
            placeholder="Enter URL" class="form-control" [ngModelOptions]="{standalone: true}" name="inputURL" />

          <div [hidden]="inputURL || file" class="text-center">
            <h5>or</h5>

            <button *ngIf="!updatingMenu" class="btn btn-warning" (click)="showAlert()">
              <strong>Create Empty</strong>
            </button>
            <button *ngIf="updatingMenu" class="btn btn-warning" (click)="showAlert()">
              <strong>Clear</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>


  <!-- <label class="btn btn-primary file-input-btn">
    Choose file
    <input type="file" hidden name="file" type="file" (change)="fileChangeEvent($event)">
  </label> -->
  <!-- <button type="button" class="btn btn-success" [hidden]="!file" (click)="uploadAll()">Upload</button> -->
  <!-- <button type="button" class="btn btn-warning" [hidden]="!file" (click)="cancelAll()">Cancel</button>
  <button type="button" class="btn btn-danger" [hidden]="!file" (click)="clearAll()">Clear</button> -->
  <!-- <a [routerLink]="['/list']" class="btn btn-link" role="button">Downloads</a> -->
  <div class="files previews">
    <!-- <app-file-upload *ngFor="let file of files; let oddRow=odd;" [oddRow]="oddRow" [fileObject]="file">
    </app-file-upload> -->
    <app-file-upload *ngIf="file" [oddRow]="false" [fileObject]="file" [entity]="selectedEntity">
    </app-file-upload>
  </div>

  <div class="row">
    <div class="col"></div>
    <div class="col-xs-12 col-sm-6 col-md-4">
      <app-url-upload *ngIf="inputURL" [inputURL]="inputURL" [entity]="selectedEntity"
        (inputURLChange)="inputURLChange($event)">
      </app-url-upload>
      <div class="frontside">
        <div class="text-center">
          <button type="button" class="btn btn-success" [hidden]="!file" (click)="uploadAll()">Upload</button>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>


</div>

<div [hidden]="hideAlert" class="fixed-top col text-center">
  <div class="alert alert-warning alert-dismissible fade show" role="alert">
    <p><strong>Are you sure?</strong></p>
    <p>There will be no data associated with this code</p>
    <button type="button" class="btn btn-primary" (click)="dismissAlert()">
      <i class="fa fa-close" aria-hidden="true"></i>
      Cancel
    </button>
    <button type="button" class="btn btn-danger" (click)="alertConfirm()">
      <i class="fa fa-check" aria-hidden="true"></i>
      Yes
    </button>
    <button type="button" class="close" (click)="dismissAlert()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>