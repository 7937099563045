import { Component, OnInit, NgZone, ViewChild, AfterViewInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpUrlEncodingCodec } from '@angular/common/http';
import * as QRCode from 'easyqrcodejs';
import { GeneratedQRObject } from '../types';
import { environment } from '../../../environments/environment';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-qrcomponent',
  templateUrl: './qrcomponent.component.html',
  styleUrls: ['./qrcomponent.component.scss']
})
export class QrcomponentComponent implements OnInit, AfterViewInit {

  @Input() url: string;
  // Your DOM Element
  @ViewChild('qrcode', { static: false }) qrcode: ElementRef;
  @ViewChild('qrcode2', { static: false }) qrcode2: ElementRef;

  name;
  nameShort;
  path;
  shortLink = '';
  isRendered = false;


  sub2;
  qrlink$;
  qrlink2$;
  qr1: GeneratedQRObject;
  qr2: GeneratedQRObject;
  qrObservable = new Subject<string>();
  qrObservable2 = new Subject<string>();

  qrCodes: GeneratedQRObject[] = [];
  zip = new JSZip();

  @Output() qrGenerated: EventEmitter<GeneratedQRObject> = new EventEmitter();
  fireQRGenerated(QR: GeneratedQRObject) {
    this.qrGenerated.emit(QR);
    this.qrGeneratedHandler(QR);
  }

  qrEventChange() {
    this.qrObservable.next(this.qrlink$);
  }
  qr2EventChange() {
    this.qrObservable2.next(this.qrlink2$);
  }

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private ngZone: NgZone
  ) {
    this.sub2 = this.qrObservable.subscribe((s) => { });
  }
  ngOnInit(): void { }

  ngAfterViewInit() {
    this.bitlyResp(this.url);
    this.plainQR(this.url);
  }



  private bitlyResp(url) {
    this.shortLink = [environment.shortUrl, url].join('/');
    this.nameShort = url;
    // Options
    var options = {
      //logo: './assets/cos2.png',
      logoHeight: 307,
      logoWidth: 307,

      text: this.shortLink,
      title: this.nameShort,
      titleFont: "100px Arial", //font. default is "bold 16px Arial"
      titleColor: "#fff", // color. default is "#000"
      titleBackgroundColor: "#007bff", // background color. default is "#fff"
      titleHeight: 200, // height, including subTitle. default is 0
      titleTop: 90, // draws y coordinates. default is 30
      subTitle: this.shortLink,
      subTitleFont: "70px Arial", // font. default is "14px Arial"
      subTitleColor: "#fff", // color. default is "4F4F4F"
      subTitleTop: 170, // draws y coordinates. default is 0
      width: 1000,
      height: 1000,
      quietZone: 20,
      colorDark: "#000000",
      colorLight: "#ffffff",
      correctLevel: QRCode.CorrectLevel.H,
      onRenderingEnd: this.onRenderingEnd
    };

    // Create new QRCode Object
    let nqrc = new QRCode(this.qrcode.nativeElement, options);
    nqrc._el.lastElementChild.style.maxWidth = '100%';
  }

  onRenderingEnd = (qrCodeOptions, base64DataURL) => {
    // this.qrlink = base64DataURL;
    //console.log(base64DataURL.toString());
    this.qrlink$ = base64DataURL;
    this.qrEventChange();
    // this.finishQREvent(base64DataURL.toString());
    // console.log(this.qrlink$);
    this.qr1 = new GeneratedQRObject(base64DataURL, this.nameShort + '-proof.png', this.shortLink);
    this.fireQRGenerated(this.qr1);
  }

  private plainQR(url) {
    this.shortLink = [environment.shortUrl, url].join('/');
    this.nameShort = url;
    // Options
    var options = {
      //logo: './assets/cos2.png',
      logoHeight: 307,
      logoWidth: 307,
      //logoBackgroundColor: '#ff0000',
      text: this.shortLink,
      quietZone: 20,
      width: 1000,
      height: 1000,
      colorDark: "#000000",
      colorLight: "#ffffff",
      correctLevel: QRCode.CorrectLevel.H,
      onRenderingEnd: this.onPlainRenderingEnd
    };

    // Create new QRCode Object
    let qrc = new QRCode(this.qrcode2.nativeElement, options);
    qrc._el.lastElementChild.style.maxWidth = '100%';
  }

  onPlainRenderingEnd = (qrCodeOptions, base64DataURL) => {
    // this.qrlink = base64DataURL;
    //console.log(base64DataURL.toString());
    this.qrlink2$ = base64DataURL;
    this.qr2EventChange();
    // this.finishQREvent(base64DataURL.toString());
    // console.log(this.qrlink$);
    this.qr2 = new GeneratedQRObject(base64DataURL, this.nameShort + '-print.png', this.shortLink);
    this.fireQRGenerated(this.qr2);
  }

  download() {

  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.sub2.unsubscribe();
  }

  qrGeneratedHandler(qr: GeneratedQRObject) {
    this.qrCodes.push(qr);
    JSZipUtils.getBinaryContent(qr.base64, (err, data) => {
      this.zip.file(qr.filename, data, { binary: true });
    });
  }
  handleDownloadAll() {

    this.zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, this.nameShort + '.zip');
    });
  }
}
