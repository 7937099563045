import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoadingComponent } from './loading/loading.component';
import { AppRoutingModule } from './app-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HttpClientModule } from '@angular/common/http';

import { SelectDropDownModule } from 'ngx-select-dropdown';

import {
  AuthService,
  FirstTimePasswordComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  SigninComponent,
  SignoutComponent
} from './auth';

import {
  UploadContainerComponent,
  FileUploadComponent,
  UploadService
} from './upload';
import { FileSizePipe } from '../utils';

import { DownloadComponent, DownLoadService } from './download';
import { NgxPaginationModule } from 'ngx-pagination';
import { BatchComponent } from './batch/batch.component';
import { QrcomponentComponent } from './batch/qrcomponent/qrcomponent.component';
import { EditComponent } from './edit/edit.component';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { TestingComponent } from './testing/testing.component';
import { RegisterComponent } from './auth/register/register.component';
import { ConfirmComponent } from './auth/register/confirm/confirm.component';
import { UrlUploadComponent } from './upload/url-upload/url-upload.component';
import { SizeDetectorComponent } from './size-detector/size-detector.component';
import { ResizeService } from './size-detector/resize.service';
import { ResendComponent } from './auth/register/resend/resend.component';
import { BatchUploadComponent } from './upload/batch-upload/batch-upload.component';
import { AdminComponent } from './admin/admin.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    FileSizePipe,
    FileUploadComponent,
    DownloadComponent,
    UploadContainerComponent,
    LoadingComponent,
    FirstTimePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SigninComponent,
    SignoutComponent,
    BatchComponent,
    QrcomponentComponent,
    EditComponent,
    TestingComponent,
    RegisterComponent,
    ConfirmComponent,
    UrlUploadComponent,
    SizeDetectorComponent,
    ResendComponent,
    BatchUploadComponent,
    AdminComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxDatatableModule,
    NgxPaginationModule,
    HttpClientModule,
    ZXingScannerModule,
    SelectDropDownModule
  ],
  bootstrap: [AppComponent],
  providers: [
    UploadService,
    DownLoadService,
    AuthService,
    ResizeService
  ]
})
export class AppModule { }
