import { version } from '../../package.json';
import { globalEnv } from './config.env';

export const environment = {
  production: true,
  bucket: 'm2data-prod',
  cognitoIdentityPool: 'us-west-2:602e46ec-40a2-48c7-9420-a965c7c2b95b',
  shortUrl: 'https://m2l.io',
  redirectUrl: 'https://view.m2l.io',
  parkedUrl: 'https://parked.m2l.io',
  versionInfo: version, // [[DEV] version from package.json and we should be able to override with a string]
  versionColor: '#000000',
  versionHash: globalEnv.CODEBUILD_RESOLVED_SOURCE_VERSION,
  baseApiUrl: 'https://7g2vmkxdhk.execute-api.us-west-2.amazonaws.com/v1',
  userPool: {
    UserPoolId: 'us-west-2_pJbj2jqWz',
    ClientId: '2kmu1ri3fnia8meruropikg68h',
    region: 'us-west-2'
  },
  SAFEKey: 'AIzaSyD4vlgAL0Ng891C6hJ57RSoSkhwJl6N3O4'
  // [no path - everything at root of the bucket with the exception of the code folder name]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
