import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoadingComponent } from './loading/loading.component';
import { DownloadComponent } from './download';
import { BatchComponent } from './batch/batch.component';
import {
  FirstTimePasswordComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  SigninComponent,
  SignoutComponent
} from './auth';

import { UploadContainerComponent } from './upload';
import { EditComponent } from './edit/edit.component';
import { TestingComponent } from './testing/testing.component';
import { RegisterComponent } from './auth/register/register.component';
import { ConfirmComponent } from './auth/register/confirm/confirm.component';
import { ResendComponent } from './auth/register/resend/resend.component';
import { BatchUploadComponent } from './upload/batch-upload/batch-upload.component';
import { AdminComponent } from './admin/admin.component';
import { UserGuard } from './guards/user.guard';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
  { path: '', redirectTo: '/menus', pathMatch: 'full', canActivate: [UserGuard] },
  {
    path: 'menus',
    children: [
      {
        path: '',
        component: DownloadComponent,
        canActivate: [UserGuard]
      },
      {
        path: 'upload',
        component: UploadContainerComponent,
        canActivate: [UserGuard]
      },
      {
        path: 'batch',
        component: BatchComponent,
        canActivate: [UserGuard]
      },
      {
        path: 'batchupload',
        component: BatchUploadComponent,
        canActivate: [UserGuard]
      },
      {
        path: 'testing',
        component: TestingComponent,
        canActivate: [UserGuard]
      },
      {
        path: 'edit',
        component: EditComponent,
        canActivate: [UserGuard]
      },
    ],
    canActivate: [UserGuard]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [UserGuard, AdminGuard]
  },



  //{ path: 'home', component: DownloadComponent },
  {
    path: 'signup',
    component: RegisterComponent
  },
  {
    path: 'signup/confirm/:username',
    component: ConfirmComponent
  },
  { path: 'resend', component: ResendComponent },
  {
    path: 'first-time-password',
    component: FirstTimePasswordComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'signout',
    component: SignoutComponent
  },
  {
    path: 'signin',
    component: SigninComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }