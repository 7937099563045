<div class="row signin justify-content-center">
  <div class="col-lg-4 text-center element-center">
    <form #signinFrom="ngForm" (ngSubmit)="signin($event)" method="post">
      <h2 class="form-signin-heading text-capitalize">Sign in</h2>
      <p *ngIf="statusMessage" class="alert {{statusClass}}">
        {{statusMessage}}
      </p>
      <div class="form-group">
        <label for="inputUsername" class="sr-only">Username</label>
        <input type="text" id="inputUsername" [(ngModel)]="username" placeholder="Username" class="form-control"
          name="username" required autofocus />
        <div *ngIf="formErrors && formErrors.username" class="alert alert-danger">
          {{ formErrors.username }}
        </div>
      </div>
      <div class="form-group">
        <label for="inputPassword" class="sr-only">Password</label>
        <input type="password" id="inputPassword" [(ngModel)]="password" placeholder="Password" class="form-control"
          required name="password" />
        <div *ngIf="formErrors && formErrors.password" class="alert alert-danger">
          {{ formErrors.password }}
        </div>

      </div>
      <div *ngIf="submissionError" class="alert alert-danger">
        {{ submissionError }}
      </div>


      <div class="form-group">
        <button class="btn btn-lg btn-primary text-capitalize btn-block" type='submit'
          [disabled]="!signinFrom.form.valid || submitted">
          <i class="fa fa-lock" aria-hidden="true"></i>
          Sign in
        </button>
      </div>
      <hr>
      <div class="form-group">
        <a routerLink="/forgot-password"><strong>Forgot Password?</strong></a>
      </div>
      <div class="form-group">
        <a routerLink="/signup"><strong>Sign Up</strong></a>
      </div>
    </form>
    <app-loading *ngIf="submitted"></app-loading>
  </div>
</div>