import { Component, Input, OnDestroy, ApplicationRef, NgZone } from '@angular/core';
import {
  FileObject,
  ContainerEvents,
  FileObjectStatus,
} from '../types';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadService } from '../service';
import { DownLoadService } from '../../download/service';
import { AuthService } from '../../auth/service';
import { Subscription, Observable, BehaviorSubject, throwError } from 'rxjs';
import { S3 } from 'aws-sdk';
import { HttpClient, HttpUrlEncodingCodec, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { User } from 'src/app/auth';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { catchError } from 'rxjs/operators';
/**
 * Single file upload component.
 */
@Component({
  moduleId: module.id,
  selector: 'app-file-upload',
  templateUrl: 'component.html',
  styleUrls: ['component.scss']
})
export class FileUploadComponent implements OnDestroy {

  @Input() fileObject: FileObject;
  @Input() oddRow: boolean;
  @Input() entity;
  FileObjectStatus = FileObjectStatus;
  progress = 0;
  speed = 0;
  uploadError: string;
  containerEventSubscription: Subscription;
  uploadHandle: any;
  shortLink = '';
  shortCode;
  entityId;
  hasPatched = false;

  constructor(
    private uploadService: UploadService,
    private router: Router,
    private downloadService: DownLoadService,
    private http: HttpClient,
    private auth: AuthService,
    public route: ActivatedRoute,
    private ngZone: NgZone) {
    this.containerEventSubscription = uploadService.uploadContrainerEvent$.subscribe(
      containerEvent => this.handleContainerEvent(containerEvent)
    );
  }

  private handleContainerEvent(containerEvent: ContainerEvents) {
    if (containerEvent === ContainerEvents.Upload) {
      return this.fileObject.status === FileObjectStatus.NotStarted && this.upload();
    } else if (containerEvent === ContainerEvents.Cancel) {
      return this.fileObject.status === FileObjectStatus.Uploading && this.cancel();
    } else if (containerEvent === ContainerEvents.Delete) {
      return this.clear();
    }
  }
  uploadFile() {

  }

  upload() {
    // this.fileObject.status = FileObjectStatus.Uploading;
    // this.uploadError = undefined;
    // this.progress = 0;
    // this.uploadHandle = this.uploadService.upload(this.fileObject.file, this.handleS3UploadProgress());
    // let key = [s3Config.key, this.fileObject.file.name].join('/');

    // this.getShort(this.downloadService.getStaticUrl(key)).subscribe((resp) => { this.ngZone.run(() => this.bitlyResp(resp)); });
    if (!this.entity.shortCode) {
      console.log("no code")
      this.auth.getAccess((err, user) => {
        this.entityId = this.entity.id;
        this.uploadService.createCode(user, this.entityId).subscribe((resp) => { this.ngZone.run(() => this.createCodeResp(resp)); });
      });
    } else {
      console.log("has code")
      this.entityId = this.entity.entityId;
      this.ngZone.run(() => this.createCodeResp(this.entity));
    }
  }

  private createCodeResp(response) {
    console.log("create code response:\n" + JSON.stringify(response, null, 4));
    this.fileObject.status = FileObjectStatus.Uploading;
    this.shortCode = response.shortCode;

    this.uploadError = undefined;
    this.progress = 0;
    let name = this.makeRandName(8, this.shortCode.substring(0, 4));
    name += '.' + this.fileObject.file.name.split('.').pop();
    // const path: string = this.entity.path.replace(' > ', '/');


    const fullPath = [this.shortCode, name].join('/');
    console.log("path: " + fullPath)
    this.uploadHandle = this.uploadService.upload(this.fileObject.file, fullPath, this.handleS3UploadProgress());

    this.shortLink = [environment.shortUrl, this.shortCode].join('/');
    const longPath = [environment.redirectUrl, this.shortCode, name].join('/');
    this.auth.getAccess((err, user) => { this.uploadService.sendPatchMenu(user, response.id, this.entityId, name, fullPath, longPath, response.notes).subscribe((resp) => { this.ngZone.run(() => this.patchResp(resp)); }); });
  }
  private patchResp(response) {
    this.hasPatched = true;
    this.edit();
  }

  private bitlyResp(response) {
    this.shortLink = response.link;
  }
  private handleS3UploadProgress() {
    return (error: Error, progress: number, speed: number) => {
      if (error) {
        this.progress = 0;
        this.speed = 0;
        this.uploadError = error.message;
        this.fileObject.status = FileObjectStatus.Failed;
      } else {
        this.progress = progress || this.progress;
        this.speed = speed || this.speed;
        if (this.progress === 100) {
          this.fileObject.status = FileObjectStatus.Uploaded;
          this.edit();
        }
      }
    };
  }
  cancel() {
    if (this.fileObject.status === FileObjectStatus.Uploading) {
      this.fileObject.status = FileObjectStatus.Canceled;
      this.uploadService.cancel(this.uploadHandle);
    }
  }
  clear() {
    if (this.fileObject.status !== FileObjectStatus.Uploading) {
      this.fileObject.status = FileObjectStatus.Deleted;
      this.uploadService.publishFileUploadEvent(this.fileObject);
    }
  }
  edit() {
    if (this.hasPatched && this.fileObject.status === FileObjectStatus.Uploaded) {
      this.router.navigate(['../edit'], { relativeTo: this.route, queryParams: { shortcode: this.shortCode } });
    }
  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.containerEventSubscription.unsubscribe();
  }
  makeRandName(length, prefix = '') {
    let result = prefix;
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
