import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from '../../service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})
export class ConfirmComponent implements OnInit {
  confirmationCode: string;
  email: string;
  errorMessage: string;
  private sub: any;
  submitted = false;

  constructor(private authService: AuthService, private router: Router, public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.email = params['username'];

    });

    this.errorMessage = null;
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  onConfirmRegistration() {
    this.submitted = true;
    this.errorMessage = null;
    this.authService.confirmRegistration(this.email, this.confirmationCode, this.callback);
  }

  callback = (message, result: any) => {
    console.log(message)
    if (message != null) { //error
      this.submitted = false;
      this.errorMessage = message;
      console.log("message: " + this.errorMessage);
    } else { //success
      //move to the next step
      console.log("Moving to home");
      // this.configs.curUser = result.user;
      this.router.navigate(['/menus']);
    }
  }

}
