<div role="main" class=" flex-shrink-0">
  <div class="col">
    <h1 class="text-center section-title">Testing</h1>
    <div class="row">
      <div class="col"></div>
      <div class="col-xs-12 col-sm-8 col-md-6">
        <div class="frontside">
          <div class="card">
            <div class="card-body text-center">
              <h3>GET Menus List</h3>
              <button type="button" class="btn btn-block btn-danger mt-2 mb-2" (click)="menusButton()">Test!</button>
            </div>
            <div class="card-body text-left">
              <p *ngIf="menuErrorData" class="alert text-danger">
                {{menuErrorData}}
              </p>
              <p *ngIf="menuReturnedData" class="alert text-info" style="white-space: pre-line">
                {{menuReturnedData}}
              </p>
            </div>
          </div>
        </div>

        <div class="frontside">
          <div class="card">
            <div class="card-body text-center">
              <h3>GET User Entities</h3>
              <button type="button" class="btn btn-block btn-danger mt-2 mb-2"
                (click)="getEntitiesButton()">Test!</button>
            </div>
            <div class="card-body text-left">
              <p *ngIf="getEntitiesErrorData" class="alert text-danger">
                {{getEntitiesErrorData}}
              </p>
              <p *ngIf="getEntitiesReturnedData" class="alert text-info" style="white-space: pre-line">
                {{getEntitiesReturnedData}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
</div>