<div class="text-center">
  <button [hidden]="!checkIfURL()" type="button" class="btn btn-success" (click)="onURLSubmit()">Create</button>
  <button type="button" class="btn btn-danger" (click)="cancel()">Cancel</button>
</div>
<div [hidden]="hideAlert" class="col text-center">
  <div class="alert alert-warning alert-dismissible fade show" role="alert">
    <p><strong>URL Warning</strong></p>
    <p>Your destination URL is not safe.</p>
    <button type="button" class="close" (click)="dismissAlert()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>