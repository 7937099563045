import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthService } from '../service';
import { Router } from '@angular/router';


import { SignupForm, RegistrationUser } from '../types';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  username: string;
  password: string;
  submissionError: string;
  submitted = false;
  formErrors: SignupForm = {};
  statusMessage: string;
  statusClass: string;
  signinFrom: string = '';
  registrationUser: RegistrationUser;
  errorMessage: string;
  phone: string;
  phoneArea = '+1';

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.registrationUser = new RegistrationUser();
    this.errorMessage = null;
  }


  onRegisterNew($event) {
    this.errorMessage = null;
    this.submitted = true;
    // Disable default submission.
    $event.preventDefault();

    if (this.phone) {
      this.registrationUser.phone_number = this.phoneArea + this.phone;
    }


    this.authService.register(this.registrationUser, this.callback);



    // this.authService.authenticate({
    //   username: this.username,
    //   password: this.password
    // },
    //   (err, statusCode) => {
    //     this.submitted = false;
    //     if (statusCode === AuthService.statusCodes.newPasswordRequired) {
    //       this.router.navigate(['first-time-password']);
    //     } else if (statusCode === AuthService.statusCodes.signedIn) {
    //       this.authService.handleRedirect();
    //       return;
    //     } else if (statusCode === AuthService.statusCodes.noSuchUser) {
    //       this.submissionError = 'Email or password is not valid.';
    //     } else if (statusCode === AuthService.statusCodes.unknownError) {
    //       this.submissionError = err.message;
    //     }
    //   });
  }

  onRegister() {
    this.errorMessage = null;
    this.submitted = true;
    if (this.phone) {
      this.registrationUser.phone_number = this.phoneArea + this.phone;
    }

    this.authService.register(this.registrationUser, this.callback);

    //   this.authService.authenticate({
    //     username: this.username
    //   },
    //     (err, statusCode) => {
    //       this.submitted = false;
    //       if (statusCode === AuthService.statusCodes.newPasswordRequired) {
    //         this.router.navigate(['first-time-password']);
    //       } else if (statusCode === AuthService.statusCodes.signedIn) {
    //         this.authService.handleRedirect();
    //         return;
    //       } else if (statusCode === AuthService.statusCodes.noSuchUser) {
    //         this.submissionError = 'Email or password is not valid.';
    //       } else if (statusCode === AuthService.statusCodes.unknownError) {
    //         this.submissionError = err.message;
    //       }
    //     });
    // }

  }
  callback = (message, result) => {
    if (message != null) { //error
      this.submitted = false;
      this.errorMessage = message.message;
      console.log("result: " + this.errorMessage);
    } else { //success
      //move to the next step
      console.log("redirecting");
      this.router.navigate(['/signup/confirm', result.user.username]);
    }
  }
}
