import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { DownLoadService } from './service';
import { AuthService, User } from '../auth';
import { S3 } from 'aws-sdk'
import { FileSizeUtil, MonthUtil } from '../../utils'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { NgForm } from '@angular/forms';
import { ResizeService, SCREEN_SIZE } from '../size-detector/resize.service';
import { delay } from 'rxjs/operators';

import { environment } from '../../environments/environment';


@Component({
  moduleId: module.id,
  selector: 'app-download',
  templateUrl: 'component.html',
  styleUrls: ['component.scss']
})
export class DownloadComponent implements OnInit {
  signedInUser: any;
  entitiesList = [];
  visibleEntitiesList = [];
  toplevelEntitiesList;
  selectedTopEntity = 'None';
  selectedEntity: string;
  menusList = [];
  loading = true;

  config = {
    //displayKey: "path", // if objects array passed which key to be displayed defaults to description
    search: true,
    //limitTo: 10,
  };

  size: SCREEN_SIZE;

  rows = [
  ];
  columns = [

    { prop: 'name' },
    { name: 'ID' },
    { name: 'Filename' },
    { name: 'Shortcode' },
    { name: 'Updated' }
  ];

  constructor(private authService: AuthService,
    private router: Router,
    private downloadService: DownLoadService,
    private http: HttpClient,
    private ngZone: NgZone, private resizeSvc: ResizeService) {
    this.resizeSvc.onResize$
      .pipe(delay(0))
      .subscribe(x => {
        this.size = x;
      });
  }

  ngOnInit(): void {


    this.authService.getCurrentUser((err, user: User) => {
      this.signedInUser = user;
      this.downloadService.setSignedInUser(this.signedInUser);
      // if (!this.signedInUser || !this.signedInUser.signedIn) {
      //   // this.authService.redirectToSignin(this.router.routerState.snapshot.root.queryParams);
      //   this.authService.redirectToSignin();
      //   return;
      // } else {
      this.loading = true;
      this.authService.getAccess((err, user) => {
        this.getEntitiesList(user).subscribe((resp) => {
          this.ngZone.run(() => this.entitiesListResp(resp));
        });
        this.getMenusList(user).subscribe((resp) => {
          this.ngZone.run(() => this.menusListResp(resp));
        });
      });
      // }
    });
  }
  getMenusList(user: CognitoIdToken): Observable<Object> {
    return this.http.get<string>(environment.baseApiUrl + '/menus', {
      headers: {
        ['Authorization']: user.getJwtToken(),
        ['Content-Type']: 'application/json'
      }
    });
  }
  getMenusListByID(user: CognitoIdToken, id): Observable<Object> {
    return this.http.get<string>(environment.baseApiUrl + '/menus/findbyid/' + id, {
      headers: {
        ['Authorization']: user.getJwtToken(),
        ['Content-Type']: 'application/json'
      }
    });
  }
  private menusListResp(response) {
    this.menusList = response;
    this.rows = [];




    this.loading = false;
  }
  getEntitiesList(user: CognitoIdToken): Observable<Object> {
    return this.http.get<string>(environment.baseApiUrl + '/entities/userentities', {
      headers: {
        ['Authorization']: user.getJwtToken(),
        ['Content-Type']: 'application/json'
      }
    });
  }
  private entitiesListResp(response) {
    this.entitiesList = response;
    this.toplevelEntitiesList = ['All', 'None'];
    this.entitiesList.forEach(element => {
      if (!element.path.includes(' > ')) {
        this.toplevelEntitiesList.push(element.path);
      }
    });
    console.log(response);
  }
  selectEntity(selectEntity) {
    console.log(selectEntity)
    this.visibleEntitiesList = [];

    if (this.selectedTopEntity === 'All') {
      this.visibleEntitiesList = [...this.menusList];
      console.log('all')
    } else if (this.selectedTopEntity === 'None') {
      this.visibleEntitiesList = [];
      console.log('none')
    }
    else {
      this.menusList.forEach(element => {
        console.log(`${element.entityName} | ${this.selectedTopEntity}`)
        if (element.entityName.split(' > ')[0] === this.selectedTopEntity) {
          this.visibleEntitiesList.push(element);
        }
      });
    }
    console.log(this.visibleEntitiesList)

    this.SetupTable();





    // this.visibleEntitiesList = this.entitiesList



  }
  SetupTable() {
    if (this.visibleEntitiesList.length < 1) {
      this.rows = [];
      return;
    }
    const dateOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }

    this.rows = this.visibleEntitiesList.map((data) => {
      const row: any = {};
      row.entityName = data.entityName;
      row.ID = data.id;
      row.Filename = data.filename;
      row.path = data.bucketPath;
      row.redirectURL = data.redirectUrl;
      row.Shortcode = data.shortCode;
      row.entityID = data.entityId;
      row.Updated = new Date(data.updatedAt).toLocaleString(undefined, dateOptions);
      row.updatedBy = data.updatedByName;
      row.details = data.bucketPath;
      row.edit = data.shortCode;
      return row;
    });
  }
}
