<div class="d-none">
  <div class="">
    <div class="mb-2 pl-2 pr-2" #qrcode></div>
    <a *ngIf="qrlink$" class="btn-block btn-lg btn-primary text-center" href='{{qrlink$}}'
      download='{{nameShort}}-proof.png'>
      <i class="fa fa-download" aria-hidden="true"></i>

    </a>
  </div>
</div>
<div class="card-columns mx-auto">
  <div class="card">
    <div class="card-body">
      <div class="mb-2 pl-2 pr-2" #qrcode2></div>
      <button *ngIf="qrlink2$" class="btn-block btn-lg btn-primary text-center" (click)="handleDownloadAll()">
        <i class="fa fa-download" aria-hidden="true"></i>

      </button>
    </div>
  </div>
</div>