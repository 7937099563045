<div class="footerMain">
    <footer class="fixed-bottom  bg-white border-top border-black">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center" style="pointer-events: all">

                    <a class="footerlink" target="_blank" href="/terms">Terms & Conditions</a>
                    <span class="copyright">|</span>
                    <a class="footerlink" target="_blank" href="/privacy">Privacy Policy</a>
                </div>
                <div class="col-12 text-center" style="pointer-events: all">
                    <span class="copyright">
                        Copyright &copy; YETi CGI
                        {{year}}
                    </span>
                </div>
                <div class="col-12 text-center" style="pointer-events: all">
                    <span style="color: {{versionColor}}">
                        {{version}}
                    </span>
                </div>
            </div>
        </div>
    </footer>
</div>