import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';


export enum SCREEN_SIZE {
  XS,
  SM,
  MD,
  LG,
  XL
}

@Injectable()
export class ResizeService {

  get onResize$(): Observable<SCREEN_SIZE> {
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }

  private resizeSubject: BehaviorSubject<SCREEN_SIZE>;

  constructor() {
    this.resizeSubject = new BehaviorSubject(SCREEN_SIZE.SM);
  }

  onResize(size: SCREEN_SIZE) {
    this.resizeSubject.next(size);
  }

}
