<div [ngClass]="{'file-row': true, row: true, odd: oddRow}">
  <div class="col-lg-4">
    <p class="name">{{fileObject.file.name}}</p>
    <p
      [ngClass]="{'text-primary': fileObject.status === FileObjectStatus.Uploading, 'text-success': fileObject.status === FileObjectStatus.Uploaded, 'text-danger': fileObject.status === FileObjectStatus.Failed}">
      <strong *ngIf="fileObject.status !== FileObjectStatus.NotStarted"
        [ngClass]="{'error': fileObject.status === FileObjectStatus.Failed}">
        {{FileObjectStatus[fileObject.status]}}
      </strong>
    </p>
    <strong class="error text-danger">{{uploadError}}</strong>
  </div>
  <div class="col-lg-4 text-right">
    <p class="size">{{fileObject.file.size | fileSize}}</p>
    <div class="progress">
      <div
        [ngClass]="{'progress-bar': true, 'progress-bar-striped': true, 'progress-bar-success': fileObject.status !== FileObjectStatus.Failed, 'progress-bar-danger': fileObject.status === FileObjectStatus.Failed }"
        role="progressbar" [ngStyle]="{'width': progress + '%'}" aria-valuenow="progress" aria-valuemin="0"
        aria-valuemax="100">
        <strong *ngIf="progress > 0">{{progress}}%</strong>
      </div>
    </div>
    <p *ngIf="fileObject.status === FileObjectStatus.Uploading" class="speed">{{speed | fileSize}}/s</p>
  </div>
  <div class="col-lg-4 text-right">
    <!-- <button class="btn btn-outline-warning start" (click)="testbutton()">
      <i class="glyphicon glyphicon-upload"></i>
      <strong>test</strong>
    </button> -->
    <button [hidden]="fileObject.status !== FileObjectStatus.NotStarted" class="btn btn-danger cancel"
      (click)="clear()">
      <i class="glyphicon glyphicon-ban-circle"></i>
      <strong>Cancel</strong>
    </button>
    <button [hidden]="fileObject.status !== FileObjectStatus.Uploading" class="btn btn-warning cancel"
      (click)="cancel()">
      <i class="glyphicon glyphicon-ban-circle"></i>
      <strong>Cancel</strong>
    </button>
    <button [hidden]="fileObject.status !== FileObjectStatus.Uploaded" class="btn btn-danger delete" (click)="edit()">
      <i class="glyphicon glyphicon-trash"></i>
      <strong>Edit</strong>
    </button>
    <a [hidden]="fileObject.status !== FileObjectStatus.Uploaded" class="btn btn-info ml-1" target="_blank"
      href={{shortLink}}><strong>View</strong></a>
  </div>
</div>