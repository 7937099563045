<div class="container">
  <h2 class="section-title text-center">Admin</h2>


  <div class="row">
    <div class="w-100">
      <div class="col">
        <div class="row text-center">
          <div class="col">
            <a class="btn btn-info">
              <i class="fa fa-user" aria-hidden="true"></i>
              Invite User
            </a>
            <!-- [routerLink]="['../upload']" [queryParams]="{shortcode: shortcode}" -->
            <a class="btn btn-warning">
              <i class="fa fa-check" aria-hidden="true"></i>
              Approve Users
            </a>


            <!-- <button type="button" class="btn btn-secondary" (click)="reset()">
              <i class="fa fa-search" aria-hidden="true"></i>
              Edit a Different Menu
            </button> -->

          </div>
        </div>
      </div>
    </div>
  </div>
</div>