import { Injectable } from '@angular/core';
import { Subject, Observable, throwError } from 'rxjs';
import { User } from '../auth/types';
import { ContainerEvents, FileObject } from './types';
import { S3 } from 'aws-sdk';
import { S3Factory } from '../../utils';

import { environment } from '../../environments/environment';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpUrlEncodingCodec, HttpErrorResponse } from '@angular/common/http';


@Injectable()
export class UploadService {

  // Observable string sources
  private uploadContainerEventSource = new Subject<ContainerEvents>();
  private fileUploadEventSource = new Subject<FileObject>();

  // Observable string streams
  uploadContrainerEvent$ = this.uploadContainerEventSource.asObservable();
  fileUploadEvent$ = this.fileUploadEventSource.asObservable();
  private signedInUser: User;
  private region: string;

  constructor(private http: HttpClient) {
    this.region = environment.userPool.region || 'us-west-2';
  }

  setSignedInUser(user: User) {
    this.signedInUser = user;
  }

  // Upload status updates
  publishUploadContainerEvent(event: ContainerEvents) {
    this.uploadContainerEventSource.next(event);
  }

  publishFileUploadEvent(file: FileObject) {
    this.fileUploadEventSource.next(file);
  }

  setRegion(region: string) {
    this.region = region;
  }

  private preparePutObjectRequest(file: File, region: string, path: string): S3.Types.PutObjectRequest {
    const now = new Date();
    const obj = {
      Key: path,
      Bucket: environment.bucket,
      Body: file,
      ContentType: file.type
    };
    // const obj = {
    //   Key: [this.signedInUser.username,
    //   this.signedInUser.userId,
    //   now.getUTCFullYear(),
    //   now.getUTCMonth(),
    //   now.getUTCDate(),
    //   file.name].join('/'),
    //   Bucket: s3Config.buckets[region],
    //   Body: file,
    //   ContentType: file.type
    // };
    console.log(obj);
    return obj;

  }

  upload(file: File, path: string, progressCallback: (error: Error, progress: number, speed: number) => void, region?: string) {
    if (!this.signedInUser) {
      progressCallback(new Error('User not signed in'), undefined, undefined);
      return;
    }
    region = region || this.region;
    const s3Upload = S3Factory.getS3(region).upload(this.preparePutObjectRequest(file, region, path));
    s3Upload.on('httpUploadProgress', this.handleS3UploadProgress(progressCallback));
    s3Upload.send(this.handleS3UploadComplete(progressCallback));
    return s3Upload;
  }

  private handleS3UploadProgress
    (progressCallback: (error: Error, progress: number, speed: number) => void) {
    let uploadStartTime = new Date().getTime();
    let uploadedBytes = 0;
    return (progressEvent: S3.ManagedUpload.Progress) => {
      const currentTime = new Date().getTime();
      const timeElapsedInSeconds = (currentTime - uploadStartTime) / 1000;
      if (timeElapsedInSeconds > 0) {
        const speed = (progressEvent.loaded - uploadedBytes) / timeElapsedInSeconds;
        const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        progressCallback(undefined, progress, speed);
        uploadStartTime = currentTime;
        uploadedBytes = progressEvent.loaded;
      }
    };
  }

  private handleS3UploadComplete(
    progressCallback: (error: Error, progress: number, speed: number) => void) {
    return (error: Error, data: S3.ManagedUpload.SendData) => {
      if (error) {
        progressCallback(error, undefined, undefined);
      } else {
        progressCallback(error, 100, undefined);
      }
    };
  }

  cancel(s3Upload: S3.ManagedUpload) {
    s3Upload.abort();
  }
  createEmptyCode(user: CognitoIdToken, entityID): Observable<Object> {

    // return this.http.get<string>('https://jc43ylesp1.execute-api.us-west-2.amazonaws.com/dev/menu', {
    const body = {
      entityId: entityID
    }
    console.log(body);
    return this.http.post<string>(environment.baseApiUrl + '/magiclinks/generate', body, {
      headers: {
        ['Authorization']: user.getJwtToken(),
        ['Content-Type']: 'application/json'
      }
    }).pipe(
      catchError((err) => this.handleError(err)) // then handle the error
    );
  }

  createCode(user: CognitoIdToken, entityID): Observable<Object> {

    // return this.http.get<string>('https://jc43ylesp1.execute-api.us-west-2.amazonaws.com/dev/menu', {
    const body = {
      entityId: entityID
    }
    console.log(body);
    return this.http.post<string>(environment.baseApiUrl + '/menus/createcode', body, {
      headers: {
        ['Authorization']: user.getJwtToken(),
        ['Content-Type']: 'application/json'
      }
    }).pipe(
      catchError((err) => this.handleError(err)) // then handle the error
    );
  }
  sendPatchMenu(user: CognitoIdToken, menuId, entityId, filename: string, bucketPath: string, redirectUrl: string, notes: string = ''): Observable<Object> {
    const body = {
      "entityId": entityId,
      "filename": filename,
      "bucketPath": bucketPath,
      "redirectUrl": redirectUrl,
      "notes": notes
    }
    return this.http.patch<string>(environment.baseApiUrl + '/menus/' + menuId, body, {
      headers: {
        ['Authorization']: user.getJwtToken(),
        ['Content-Type']: 'application/json'
      }
    }).pipe(
      catchError((err) => this.handleError(err)) // then handle the error
    );
  }
  getShort(url: string): Observable<string> {
    return this.http.post<string>(environment.baseApiUrl + '/menus/createcode', {
      long_url: url
    }, {
      headers: {
        ['Content-Type']: 'application/json',
      }
    });
  }
  handleError(error: HttpErrorResponse) {
    //this.menuErrorData = JSON.stringify(error)
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
