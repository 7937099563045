<div class="container">

  <h2 class="section-title text-center">Batch Upload</h2>
  <div class="row">

    <div class="col">

      <div class="text">
        <a [routerLink]="['../']" class="btn btn-primary m-0" role="button"><i class="fa fa-list"
            aria-hidden="true"></i>
          Main
          Menu</a>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <h4 *ngFor="let error of getMenuErrorData" class="alert alert-warning alert-dismissible fade show">
        {{error}}
      </h4>
      <h4 *ngFor="let success of getMenuSuccessData" class="alert alert-success alert-dismissible fade show">
        {{success}}
      </h4>
    </div>
  </div>


  <div class="row">
    <div class="col"></div>
    <div class="col-xs-12 col-sm-6 col-md-4">
      <div class="text-center">
        <label [hidden]="selectedEntities" class="btn btn-primary file-input-btn">
          Choose CSV file of shortcodes
          <input type="file" hidden name="csvfile" (change)="csvfileChangeEvent($event)">
        </label>
      </div>
      <div class="text-center">
        <label [hidden]="!selectedEntities || beganUpload" class="btn btn-primary file-input-btn">
          <strong>Upload file</strong>
          <input type="file" hidden name="file" (change)="fileChangeEvent($event)">
        </label>
        <!-- <h5 [hidden]="file || inputURL">or</h5>
        <input [hidden]="file" type="url" id="inputURLField" name="inputURLField" [(ngModel)]="inputURL"
          placeholder="Enter URL" class="form-control" [ngModelOptions]="{standalone: true}" name="inputURL" /> -->
      </div>
    </div>
    <div class="col"></div>
  </div>

  <div class="files previews" *ngIf="file">
    <div [ngClass]="{'file-row': true, row: true, odd: false}">
      <div class="col-lg-4">
        <p class="name">{{file.file.name}}</p>
        <p
          [ngClass]="{'text-primary': file.status === FileObjectStatus.Uploading, 'text-success': file.status === FileObjectStatus.Uploaded, 'text-danger': file.status === FileObjectStatus.Failed}">
          <strong *ngIf="file.status !== FileObjectStatus.NotStarted"
            [ngClass]="{'error': file.status === FileObjectStatus.Failed}">
            {{FileObjectStatus[file.status]}}
          </strong>
        </p>
        <strong class="error text-danger">{{uploadError}}</strong>
      </div>
      <div class="col-lg-4 text-right">
        <p class="size">{{file.file.size | fileSize}}</p>
        <div class="progress">
          <div
            [ngClass]="{'progress-bar': true, 'progress-bar-striped': true, 'progress-bar-success': file.status !== FileObjectStatus.Failed, 'progress-bar-danger': file.status === FileObjectStatus.Failed }"
            role="progressbar" [ngStyle]="{'width': progress + '%'}" aria-valuenow="progress" aria-valuemin="0"
            aria-valuemax="100">
            <strong *ngIf="progress > 0">{{progress}}%</strong>
          </div>
        </div>
        <p *ngIf="file.status === FileObjectStatus.Uploading" class="speed">{{speed | fileSize}}/s</p>
      </div>
      <div class="col-lg-4 text-right">
        <!-- <button class="btn btn-outline-warning start" (click)="testbutton()">
          <i class="glyphicon glyphicon-upload"></i>
          <strong>test</strong>
        </button> -->
        <button [hidden]="file.status !== FileObjectStatus.NotStarted" class="btn btn-danger cancel" (click)="clear()">
          <i class="glyphicon glyphicon-ban-circle"></i>
          <strong>Cancel</strong>
        </button>
        <button [hidden]="file.status !== FileObjectStatus.Uploading" class="btn btn-warning cancel" (click)="cancel()">
          <i class="glyphicon glyphicon-ban-circle"></i>
          <strong>Cancel</strong>
        </button>
        <!-- <button [hidden]="file.status !== FileObjectStatus.Uploaded" class="btn btn-danger delete" (click)="edit()">
          <i class="glyphicon glyphicon-trash"></i>
          <strong>Edit</strong>
        </button>
        <a [hidden]="file.status !== FileObjectStatus.Uploaded" class="btn btn-info ml-1" target="_blank"
          href={{shortLink}}><strong>View</strong></a> -->
      </div>
    </div>
  </div>
</div>