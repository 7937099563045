import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadService } from '../service';
import { ContainerEvents, FileObject, FileObjectStatus, EntityObject } from '../types';
import { AuthService, User } from '../../auth';
import { URLUtil } from '../../../utils';
import { NgForm } from '@angular/forms';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { Observable, Subscription, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-batch-upload',
  templateUrl: './batch-upload.component.html',
  styleUrls: ['./batch-upload.component.scss']
})
export class BatchUploadComponent implements OnInit {
  file: FileObject;
  csvfile: FileObject;
  fr;
  inputURL;
  routeSub;
  signedInUser: User;
  shortcodes;
  selectedEntities;
  getMenuErrorData;
  getMenuSuccessData;

  FileObjectStatus = FileObjectStatus;
  progress = 0;
  speed = 0;
  uploadError: string;
  containerEventSubscription: Subscription;
  uploadHandle: any;

  successes = 0;
  beganUpload = false;
  // shortLink = '';
  // shortCode;
  // entityId;
  // hasPatched = false;

  constructor(private authService: AuthService,
    private router: Router,
    private uploadService: UploadService,
    private ngZone: NgZone,
    private auth: AuthService,
    private http: HttpClient, public route: ActivatedRoute) {
    this.containerEventSubscription = uploadService.uploadContrainerEvent$.subscribe(
      containerEvent => this.handleContainerEvent(containerEvent)
    );
  }
  private handleContainerEvent(containerEvent: ContainerEvents) {
    if (containerEvent === ContainerEvents.Upload) {
      return this.file.status === FileObjectStatus.NotStarted && this.upload();
    } else if (containerEvent === ContainerEvents.Cancel) {
      return this.file.status === FileObjectStatus.Uploading && this.cancel();
    } else if (containerEvent === ContainerEvents.Delete) {
      return this.clear();
    }
  }

  ngOnInit() {

    this.authService.getCurrentUser((err, user: User) => {
      this.signedInUser = user;
      this.uploadService.setSignedInUser(this.signedInUser);
      // if (!this.signedInUser || !this.signedInUser.signedIn) {
      //   // this.authService.redirectToSignin(this.router.routerState.snapshot.root.queryParams);
      //   this.router.navigate(['signin']);
      //   return;
      // } else {

      // }
    });
  }
  csvfileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files.length) {
      // const fileObject = new FileObject(fileInput.target.files[0]);
      this.csvfile = fileInput.target.files[0];
      this.handleFile();

    }
  }
  handleFile() {
    this.fr = new FileReader();
    this.fr.onload = this.receivedText;
    this.fr.readAsText(this.csvfile);
  }
  receivedText = () => {
    console.log(this.fr.result);
    let s = this.fr.result.replace(/\s/g, '');
    // this.urls = JSON.parse(this.fr.result).urls;
    this.shortcodes = s.split(',');
    this.authService.getAccess((err, user) => {
      // if query tag for updating, get entity
      this.selectedEntities = [];
      for (let i = 0; i < this.shortcodes.length; i++) {
        this.getExistingMenuFromShortcode(user, this.shortcodes[i]).pipe(
          catchError((err) => this.handleError(err, this.shortcodes[i])) // then handle the error
        ).subscribe((resp) => {
          this.ngZone.run(() => this.getExistingMenuFromShortcodeResp(resp));
        });
      }
    });
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files.length) {
      const fileObject = new FileObject(fileInput.target.files[0]);
      this.file = fileObject;
      this.upload();
    }
  }
  getExistingMenuFromShortcode(user: CognitoIdToken, shortcode): Observable<Object> {
    return this.http.get<string>(environment.baseApiUrl + '/menus/findbyshortcode/' + shortcode, {
      headers: {
        ['Authorization']: user.getJwtToken(),
        ['Content-Type']: 'application/json'
      }
    }).pipe(
      catchError((err) => this.handleError(err, shortcode)) // then handle the error
    );
  }
  getExistingMenuFromShortcodeResp(response) {
    this.selectedEntities.push(response);
    console.log("Menu response:\n" + JSON.stringify(response, null, 4));
  }
  upload() {
    console.log("Upload!")
    this.ngZone.run(() => this.uploadFile());
  }
  private uploadFile() {
    this.beganUpload = true;
    this.file.status = FileObjectStatus.Uploading;
    this.uploadError = undefined;
    this.progress = 0;
    for (let i = 0; i < this.selectedEntities.length; i++) {



      let name = this.makeRandName(8, this.selectedEntities[i].shortCode.substring(0, 4));
      name += '.' + this.file.file.name.split('.').pop();
      // const path: string = this.entity.path.replace(' > ', '/');


      const fullPath = [this.selectedEntities[i].shortCode, name].join('/');
      console.log("path: " + fullPath)
      this.uploadHandle = this.uploadService.upload(this.file.file, fullPath, this.handleS3UploadProgress());

      // this.shortLink = [environment.shortUrl, this.shortCode].join('/');
      const longPath = [environment.redirectUrl, fullPath].join('/');
      //TODO:For each
      this.auth.getAccess((err, user) => {

        this.uploadService.sendPatchMenu(
          user,
          this.selectedEntities[i].id,
          this.selectedEntities[i].entityId,
          name,
          fullPath,
          longPath,
          this.selectedEntities[i].notes).pipe(
            catchError((err) => this.handleError(err, this.selectedEntities[i].shortCode)) // then handle the error
          ).subscribe((resp) => {
            this.ngZone.run(() => this.patchResp(resp, i));
          });
      }
      );
    }

  }
  private patchResp(response, index) {
    //this.hasPatched = true;
    console.log("patched " + index + "!")
    this.publishConfirm(index);
    //TODO: next?
  }

  private handleS3UploadProgress() {
    return (error: Error, progress: number, speed: number) => {
      if (error) {
        this.progress = 0;
        this.speed = 0;
        this.uploadError = error.message;
        this.file.status = FileObjectStatus.Failed;
      } else {
        this.progress = progress || this.progress;
        this.speed = speed || this.speed;
        if (this.progress === 100) {
          this.file.status = FileObjectStatus.Uploaded;
          //TODO: next?
        }
      }
    };
  }
  cancel() {
    if (this.file.status === FileObjectStatus.Uploading) {
      this.file.status = FileObjectStatus.Canceled;
      this.uploadService.cancel(this.uploadHandle);
    }
  }
  clear() {
    if (this.file.status !== FileObjectStatus.Uploading) {
      this.file.status = FileObjectStatus.Deleted;
      this.uploadService.publishFileUploadEvent(this.file);
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.containerEventSubscription.unsubscribe();
  }
  makeRandName(length, prefix = '') {
    let result = prefix;
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  publishConfirm(index) {
    // this.hideConfirm();
    this.auth.getAccess((err, user) => {
      this.sendPublishMenuPatch(user, this.selectedEntities[index].id).subscribe((resp) => {
        this.ngZone.run(() => this.patchPublishResp(resp, index));
      });
    });

  }
  sendPublishMenuPatch(user: CognitoIdToken, menuId): Observable<Object> {
    // const body = {
    //   "entityId": this.entity.id,
    //   "filename": filename,
    //   "bucketPath": bucketPath,
    //   "redirectUrl": redirectUrl
    // }

    return this.http.patch<string>(environment.baseApiUrl + '/menus/publish/' + menuId, null, {
      headers: {
        ['Authorization']: user.getJwtToken(),
        ['Content-Type']: 'application/json'
      }
    }).pipe(
      catchError((err) => this.handleError(err)) // then handle the error
    );
  }
  private patchPublishResp(response, index) {
    //this.isPublished = response.isPublished === 1;
    console.log("Published!" + index)
    if (!this.getMenuSuccessData) {
      this.getMenuSuccessData = [];
    }
    this.getMenuSuccessData.push("Successfully published: " + this.selectedEntities[index].shortCode);
    this.successes++;
    if (this.successes >= this.selectedEntities.length) {
      this.getMenuSuccessData.push("Finished publishing!")
    }
  }

  private handleError(error: HttpErrorResponse, code?) {
    if (!this.getMenuErrorData) {
      this.getMenuErrorData = [];
    }
    let ErrorData = JSON.stringify(error, null, 4);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status) {
        ErrorData = 'Ignoring invalid code';
        if (code) {
          ErrorData += ": " + code;
        }
        this.getMenuErrorData.push(ErrorData)
      }
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message

    return throwError(
      'Something bad happened; please try again later.');
  };

}
