<div class="batch">
  <h2 class="section-title text-center">Upload File</h2>
  <div class="row">
    <div class="col"></div>
    <div class="col-xs-12 col-sm-6 col-md-4">
      <div class="frontside">
        <div class="text-center">
          <label class="btn btn-primary file-input-btn">
            Choose file
            <input type="file" hidden name="file" type="file" (change)="fileChangeEvent($event)">
          </label>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>
  <div class="row">
    <div class="col"></div>
    <div class="col-xs-12 col-sm-6 col-md-4">
      <div class="frontside">
        <div class="text-center">
          <button type="button" class="btn btn-success" [hidden]="!file" (click)="handleFile()">Process</button>

        </div>
        <div class="text-center">

          <button type="button" class="btn btn-danger mt-2 mb-2" [hidden]="qrCodes.length < 1"
            (click)="handleDownloadAll()">Download
            All</button>
        </div>
      </div>
    </div>
    <div class="col"></div>
  </div>

  <!-- <label class="btn btn-primary file-input-btn">
    Choose file
    <input type="file" hidden name="file" type="file" (change)="fileChangeEvent($event)">
  </label> -->
  <!-- <button type="button" class="btn btn-success" [hidden]="!file" (click)="uploadAll()">Upload</button> -->
  <!-- <button type="button" class="btn btn-warning" [hidden]="!file" (click)="cancelAll()">Cancel</button>
  <button type="button" class="btn btn-danger" [hidden]="!file" (click)="clearAll()">Clear</button> -->
  <!-- <a [routerLink]="['/list']" class="btn btn-link" role="button">Downloads</a> -->
  <div class="files previews">
    <div class="row">
      <div class="col-xs-12 col-sm-2 col-md-3 col-lg-2 col-xl-3"></div>
      <div class="col">
        <!-- <app-file-upload *ngIf="file" [oddRow]="true" [fileObject]="file"></app-file-upload> -->

        <app-qrcomponent *ngFor="let url of urls; let oddRow=odd;" [url]="url"
          (qrGenerated)="qrGeneratedHandler($event)"></app-qrcomponent>
      </div>
      <div class="col-xs-12 col-sm-2 col-md-3 col-lg-2 col-xl-3"></div>
    </div>
  </div>

</div>