<div class="container">
  <div class="row signin justify-content-center">
    <div class="col-lg-4 text-center element-center">
      <form class="form-signin" method="POST" action="#" role="form">
        <div class="form-group">
          <h2>Confirm Registration</h2>
        </div>
        <div *ngIf="errorMessage!=null" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div class="form-group" *ngIf="email===null">
          <label class="control-label" for="confirmationCode">Email</label>
          <input id="email" type="email" maxlength="50" class="form-control" [(ngModel)]="email"
            [ngModelOptions]="{standalone: true}">
        </div>
        <div class="form-group">
          <label class="control-label" for="confirmationCode">Confirmation Code</label>
          <input id="confirmationCode" type="text" maxlength="50" class="form-control" [(ngModel)]="confirmationCode"
            [ngModelOptions]="{standalone: true}">
        </div>
        <div class="form-group">
          <button [disabled]="submitted" (click)="onConfirmRegistration()" id="confirmRegistration" type="submit"
            class="btn btn-primary btn-block">Confirm
          </button>
        </div>
        <hr>
        <p>Can't find your code? <a [routerLink]="['/resend']"> <i class="fa fa-fw fa-group"></i> Resend
            Code</a>
          <a [routerLink]="['/signup']"> <i class="fa fa-fw fa-group"></i> Register</a> <a [routerLink]="['/login']"> <i
              class="fa fa-fw fa-user"></i> Login</a>
        </p>
      </form>
      <app-loading *ngIf="submitted"></app-loading>
    </div>
  </div>
</div>