<div class="container">
    <div class="col">
        <h2 class="section-title text-center" *ngIf="!loading && menusList.length == 0">Contact an Admin</h2>
        <h2 class="section-title text-center" *ngIf="menusList.length != 0">Magic Menu List</h2>



        <div class="row">
            <div class="col text-center">
                <app-loading *ngIf="loading"></app-loading>
            </div>
        </div>

        <div *ngIf="!loading && menusList.length > 0" class="row">
            <div class="col"></div>
            <div class="col-12 col-lg-8 col-xl-7">
                <div class="row">
                    <div class="col">
                        <!-- <form #menuSelectForm="ngForm" method="post">
                            <fieldset class="form-group">
                                <div class="form-group">
                                    <label for="menuSelect">Menu Filter</label>
                                    <select id="menuSelect" class="form-control" [(ngModel)]="selectedTopEntity"
                                        (ngModelChange)="selectEntity(menuSelectForm)" name="selectedEntity" required
                                        class="form-control">
                                        <option selected value="None">None</option>
                                        <option value="All">All</option>
                                        <option *ngFor="let menuEntity of toplevelEntitiesList;" class="form-control"
                                            [value]="menuEntity.path">
                                            {{menuEntity.path}}</option>
                                    </select>
                                </div>
                                 <button type="submit" class="btn btn-primary">Submit</button>
                        </fieldset>
                        </form> -->
                        <ngx-select-dropdown (change)="selectEntity($event)" [multiple]="false"
                            [(ngModel)]="selectedTopEntity" [config]="config" [options]="toplevelEntitiesList">
                        </ngx-select-dropdown>
                    </div>
                </div>
            </div>
            <div class="col"></div>
        </div>

        <div *ngIf="!loading && entitiesList.length > 0 && selectedTopEntity != 'None' " class="row">
            <div class="col"></div>
            <div class="col-12">

                <div class="row">
                    <div class="col">
                        <a [routerLink]="['./upload']" [queryParams]="{filter: selectedTopEntity}"
                            class="btn btn-primary" role="button"><i class="fa fa-plus" aria-hidden="true"></i></a>

                        <!-- <a [routerLink]="['/edit']" class="btn btn-warning" role="button">Edit Menu</a> -->
                    </div>
                </div>
                <div *ngIf="selectedTopEntity != 'None'" class="filesTable">
                    <ngx-datatable class="material" [rowHeight]=50 [columnMode]="'force'" [rows]="rows"
                        [footerHeight]="50" [headerHeight]="50" [limit]="10" [scrollbarH]="true">

                        <ngx-datatable-column prop="edit" name="" [width]="10">
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                <a [title]="'Manage'" [routerLink]="['./edit']" [queryParams]="{shortcode: value}"><i
                                        class="fa fa-pencil" aria-hidden="true"></i></a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column *ngIf="size <= 1" prop="redirectURL" name="" [width]="10">
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                <a href="{{value}}" target="_blank"><i class="fa fa-link" aria-hidden="true"></i></a>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column prop="Shortcode" name="Shortcode" [width]="100">
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>
                        <!-- TODO make this width larger when mobile, or scrollable dynamic. Maybe split into multiple columns -->
                        <ngx-datatable-column prop="entityName" name="Entity Name" [width]="320">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>
                        <!--
                        <ngx-datatable-column prop="ID" name="ID" [flexGrow]="1">
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="Filename" name="Filename" [flexGrow]="1">
                            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column prop="path" name="Bucket Path" [flexGrow]="1">
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column> -->

                        <ngx-datatable-column *ngIf="size >= 2" prop="redirectURL" name="Redirect URL" [width]="320">
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                <a href="{{value}}" target="_blank">{{value}}</a>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column *ngIf="size > 3" prop="Updated" name="Updated" [width]="180">
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                            <!-- TODO fix sorting to use raw date info, not converted and displayed date  -->
                        </ngx-datatable-column>
                        <!-- <ngx-datatable-column prop="updatedBy" name="Updated By" [flexGrow]="1">
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column> -->

                    </ngx-datatable>
                </div>
            </div>
            <div class="col"></div>
        </div>
        <div *ngIf="!loading && menusList.length == 0">
            <div class="col"></div>
            <div class="col">
                <p class="text-center">Your account is not attached to any locations, please email an admin to finish
                    your account setup.
                </p>
            </div>
            <div class="col"></div>

        </div>
    </div>
</div>