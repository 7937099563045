import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService, User } from '../auth';
import { Observable, throwError } from 'rxjs';
import { Dictionary } from 'src/types';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { catchError } from 'rxjs/operators';


import { environment } from '../../environments/environment';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit {

  menuReturnedData;
  menuErrorData;

  getEntitiesReturnedData;
  getEntitiesErrorData;

  constructor(
    private router: Router,
    private http: HttpClient,
    private auth: AuthService,
    private ngZone: NgZone) { }

  ngOnInit(): void {
  }

  menusButton() {
    this.auth.getAccess((err, user) => { this.getMenus(user).subscribe((resp) => { this.ngZone.run(() => this.menuResp(resp)); }); });
  }
  getMenus(user: CognitoIdToken): Observable<Object> {
    console.log(user);
    //return this.http.get<string>('https://jc43ylesp1.execute-api.us-west-2.amazonaws.com/dev/menu', {
    return this.http.get<string>(environment.baseApiUrl + '/menus', {
      headers: {
        ['Authorization']: user.getJwtToken(),
        ['Content-Type']: 'application/json'
      }
    }).pipe(
      catchError((err) => this.handleError(err)) // then handle the error
    );
  }
  private menuResp(response) {
    this.menuReturnedData = "Data: \r" + JSON.stringify(response, null, 4);
    console.log(response);
  }
  getEntitiesButton() {
    this.auth.getAccess((err, user) => { this.getEntities(user).subscribe((resp) => { this.ngZone.run(() => this.getEntitiesResp(resp)); }); });
  }
  getEntities(user: CognitoIdToken): Observable<Object> {
    console.log(user);
    //return this.http.get<string>('https://jc43ylesp1.execute-api.us-west-2.amazonaws.com/dev/menu', {
    return this.http.get<string>(environment.baseApiUrl + '/entities/userentities', {
      headers: {
        ['Authorization']: user.getJwtToken(),
        ['Content-Type']: 'application/json'
      }
    }).pipe(
      catchError((err) => this.handleError(err)) // then handle the error
    );
  }
  private getEntitiesResp(response) {
    this.getEntitiesReturnedData = "Data: \r" + JSON.stringify(response, null, 4);
    console.log(response);
  }

  private handleError(error: HttpErrorResponse) {
    this.menuErrorData = JSON.stringify(error)
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
