<nav class="navbar navbar-expand-md">
    <a class="navbar-brand" [routerLink]="['']">
        <!-- <i class="fa fa-home fa-3x" aria-hidden="true"></i> -->
        <img src="/assets/m2_logo_black_final[1].png?format=750w" alt="logo">
    </a>
    <a *ngIf="signedInUserName" [routerLink]="['/menus/edit']" [queryParams]="" class="btn btn-outline-dark"
        role="button"><i class="fa fa-qrcode fa-2x" aria-hidden="true"></i></a>
    <ul class="navbar-nav mr-auto">
    </ul>
    <ul class="navbar-nav">

        <ng-template #elseBlock>
            <li class="nav-item">
                <a class="nav-link" [routerLink]="['/signin']">Sign In</a>
            </li>
        </ng-template>
        <ng-template [ngIf]="signedInUserName" [ngIfElse]="elseBlock">
            <li *ngIf="!isProduction" class="nav-item">
                <a class="nav-link" [routerLink]="['/admin']">Admin</a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                    {{signedInUserName}}
                </a>
                <div class="dropdown-menu">
                    <a class="dropdown-item" [routerLink]="['/terms']">Terms</a>
                    <a class="dropdown-item" [routerLink]="['/signout']">Sign out</a>
                </div>
            </li>
        </ng-template>


        <!-- <li class="nav-item">
            <a class="nav-link" target="_blank" href="https://github.com/casual-simulation">
                <img src="https://s3.ap-south-1.amazonaws.com/static.tensult.com/img/github-logo.png" alt="github" width="20px">
            </a>
        </li> -->
    </ul>

    <!-- <a href=""><img src="{{githubImagePath}}" alt=""></a> -->
</nav>