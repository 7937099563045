<div class="row signin justify-content-center">
  <div class="col-lg-4 text-center element-center">
    <form
      #signinFrom="ngForm"
      class="form-signin"
      (ngSubmit)="onRegisterNew($event)"
      method="post"
    >
      <div class="form-group">
        <h2>Create account</h2>
      </div>
      <div *ngIf="errorMessage != null" class="alert alert-danger">
        {{ errorMessage }}
      </div>
      <!-- <div class="form-group">
        <label class="control-label" for="signupName">Name</label>
        <input id="signupName" type="text" maxlength="50" class="form-control" [(ngModel)]="registrationUser.name"
          [ngModelOptions]="{standalone: true}">
      </div> -->
      <div class="form-group">
        <input
          required
          id="signupUsername"
          type="text"
          maxlength="50"
          class="form-control"
          placeholder="Username"
          [(ngModel)]="registrationUser.username"
          name="username"
        />
      </div>
      <div class="form-group">
        <input
          required
          id="signupEmail"
          type="email"
          maxlength="50"
          class="form-control"
          placeholder="Email"
          [(ngModel)]="registrationUser.email"
          name="email"
        />
      </div>
      <div class="form-group w-100">
        <div class="form-inline">
          <select
            id="phoneArea"
            class="form-control"
            [(ngModel)]="phoneArea"
            name="phoneArea"
          >
            <option selected value="+1">+1</option>
          </select>
          <input
            id="signupPhone"
            type="text"
            maxlength="13"
            placeholder="Mobile Phone (optional)"
            class="form-control"
            [(ngModel)]="phone"
            name="phone"
          />
        </div>
      </div>
      <div class="form-group">
        <input
          required
          id="signupPassword"
          type="password"
          maxlength="25"
          class="form-control"
          placeholder="Password"
          length="40"
          [(ngModel)]="registrationUser.password"
          name="password"
        />
      </div>
      <div class="form-group">
        <button
          [disabled]="!signinFrom.form.valid || submitted"
          type="submit"
          class="btn btn-primary btn-block"
        >
          Create your account
        </button>
      </div>
      <hr />
      <p>
        Already have an account?
        <a [routerLink]="['/signin']"> <i class="fa fa-fw fa-lock"></i>Login</a>
      </p>
      <p>
        Resend Code?
        <a [routerLink]="['/resend']">
          <i class="fa fa-fw fa-mail-forward"></i>Resend</a
        >
      </p>
    </form>
    <app-loading *ngIf="submitted"></app-loading>
  </div>
</div>
