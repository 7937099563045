import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-resend',
  templateUrl: './resend.component.html',
  styleUrls: ['./resend.component.css']
})
export class ResendComponent implements OnInit {

  username: string;
  errorMessage: string;
  submitted = false;

  ngOnInit(): void {

    this.errorMessage = null;
  }

  constructor(public auth: AuthService, public router: Router) {

  }

  resendCode() {
    this.submitted = true;
    this.auth.resendCode(this.username, this.callback);
  }

  callback = (error: Error, result: any) => {
    if (error != null) {
      this.submitted = false;
      this.errorMessage = "Something went wrong...please try again";
    } else {
      this.router.navigate(['/signup/confirm/', this.username]);
    }
  }
}
