import { Injectable } from '@angular/core';
import { User } from '../auth/types';
import { S3Factory } from '../../utils';

import { environment } from '../../environments/environment';

@Injectable()
export class DownLoadService {

  private signedInUser: User;
  private region: string;
  private signedUrlExpireSeconds = 60 * 5;


  constructor() {
    this.region = environment.userPool.region || 'us-west-2';
  }

  setSignedInUser(user: User) {
    this.signedInUser = user;
  }

  // Upload status updates

  setRegion(region: string) {
    this.region = region;
  }

  // listFiles() {
  //   return S3Factory.getS3(this.region).listObjectsV2({
  //     Bucket: s3Config.buckets[this.region],
  //     Prefix: [this.signedInUser.username, this.signedInUser.userId].join('/')
  //   }).promise();
  // }
  listFiles(key: string = '') {
    return S3Factory.getS3(this.region).listObjectsV2({
      Bucket: environment.bucket,
      Prefix: [key].join('/')
    }).promise();
  }

  getUrl(key: string) {
    return S3Factory.getS3(this.region).getSignedUrl('getObject', {
      Bucket: environment.bucket,
      Key: key,
      Expires: this.signedUrlExpireSeconds
    });
  }
  getStaticUrl(key: string) {
    // console.log(key);
    // return S3Factory.getS3(this.region).url , {
    //   Bucket: s3Config.buckets[this.region],
    //   Key: key
    // };
    return 'https://s3.' + this.region + '.amazonaws.com/' + environment.bucket + '/' + key;
  }
}
