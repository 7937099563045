import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    // this.authService.getCurrentUser((err, currentSignedInUser) => {
    //   if (currentSignedInUser && currentSignedInUser.signedIn) {
    //     this.authService.handleRedirect();
    //   } else {
    //     this.authService.redirectToSignin();
    //   }
    // });
  }

}
