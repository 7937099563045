<div class="container">
  <div class="row signin justify-content-center">
    <div class="col-lg-4 text-center element-center">
      <form class="form-signin" method="POST" action="#" role="form">
        <div class="form-group">
          <h2>Resend Code</h2>
        </div>
        <div *ngIf="errorMessage!=null" class="alert alert-danger">
          {{ errorMessage }}
        </div>
        <div class="form-group">
          <label class="control-label" for="signupUsername">Username</label>
          <input id="signupUsername" type="text" maxlength="50" class="form-control" [(ngModel)]="username"
            [ngModelOptions]="{standalone: true}">
        </div>
        <div class="form-group">
          <button [disabled]="submitted" (click)="resendCode()" id="signupSubmit" type="submit"
            class="btn btn-primary btn-block">
            Resend Code
          </button>
        </div>

        <hr>
        <p><a [routerLink]="['/signup']"> <i class="fa fa-fw fa-group"></i> Register</a> <a [routerLink]="['/login']">
            <i class="fa fa-fw fa-user"></i> Login</a>
        </p>
      </form>
      <app-loading *ngIf="submitted"></app-loading>
    </div>
  </div>
</div>