import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, User } from '../auth';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {

  constructor(private authService: AuthService,
    private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return true;
    let promise = new Promise<boolean | UrlTree>((resolve, reject) => {
      let url: string = state.url;

      this.authService.getCurrentUser((err, user: User) => {
        // this.signedInUser = user;
        // this.downloadService.setSignedInUser(this.signedInUser);
        if (!user || !user.signedIn) {
          // this.authService.redirectToSignin(this.router.routerState.snapshot.root.queryParams);

          resolve(this.authService.GetSigninURLTree(url));
          //return(this.router.parseUrl('/'));
          // return;
        } else {
          console.log("canActivate success!")
          resolve(true);
        }
        if (err) {
          reject(this.authService.GetSigninURLTree(url))
        }
      });
    });
    return promise;
  }

}
