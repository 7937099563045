import { Component, OnInit, Input, NgZone, EventEmitter, Output } from '@angular/core';
import { AuthService } from 'src/app/auth';
import { UploadService } from '..';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-url-upload',
  templateUrl: './url-upload.component.html',
  styleUrls: ['./url-upload.component.scss']
})
export class UrlUploadComponent implements OnInit {
  @Output() inputURLChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() entity;
  @Input() inputURL;
  entityId;
  shortCode;
  hasPatched;
  hideAlert = true;

  constructor(private auth: AuthService, private uploadService: UploadService, private ngZone: NgZone, private router: Router, public route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit(): void {
  }
  onURLSubmit() {
    this.checkURL().subscribe((resp) => { this.ngZone.run(() => this.urlCheckResponse(resp)); });
  }

  checkURL(): Observable<string> {
    // return this.http.get<string>('https://jc43ylesp1.execute-api.us-west-2.amazonaws.com/dev/menu', {
    const body =
    {
      "client": {
        "clientId": 'Sazone-m2',
        "clientVersion": environment.versionInfo
      },
      "threatInfo": {
        "threatTypes": ["MALWARE"],
        "platformTypes": ["PLATFORM_TYPE_UNSPECIFIED"],
        "threatEntryTypes": ["URL"],
        "threatEntries": [
          { "url": this.inputURL }
        ]
      }
    }

    console.log(body);
    return this.http.post<string>('https://safebrowsing.googleapis.com/v4/threatMatches:find?key=' + environment.SAFEKey, body, {
      headers: {
        ['Content-Type']: 'application/json'
      }
    }).pipe(
      catchError((err) => this.uploadService.handleError(err)) // then handle the error
    );
  }
  private urlCheckResponse(response) {
    console.log("url response:\n" + JSON.stringify(response, null, 4));

    //TODO: create from url if ok
    if (response.matches === undefined) {
      //ok url
      this.dismissAlert();
      this.createFromURL();
    }
    else {
      //invalid

      this.showAlert()
    }

  }
  checkIfURL() {
    const pattern = new RegExp(/^(https?:\/\/)((([a-z\dA-Z]([a-z\dA-Z-]*[a-zA-Z\d])*)\.)+[a-zA-Z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-zA-Z\d%_.~+]*)*(\?[;&a-zA-Z\d%_.~+=-]*)?(\#[-a-zA-Z\d_]*)?$/); // fragment locator
    return !!pattern.test(this.inputURL);
  }
  createFromURL() {

    console.log(`input url was ${this.inputURL}`);
    if (!this.entity.shortCode) {
      console.log("no code")
      this.auth.getAccess((err, user) => {
        this.entityId = this.entity.id;
        this.uploadService.createCode(user, this.entityId).subscribe((resp) => { this.ngZone.run(() => this.createCodeResp(resp)); });
      });
    } else {
      console.log("has code")
      this.entityId = this.entity.entityId;
      this.ngZone.run(() => this.createCodeResp(this.entity));
    }
  }
  private createCodeResp(response) {
    console.log("create code response:\n" + JSON.stringify(response, null, 4));
    this.shortCode = response.shortCode;

    this.auth.getAccess((err, user) => { this.uploadService.sendPatchMenu(user, response.id, this.entityId, null, null, this.inputURL, response.notes).subscribe((resp) => { this.ngZone.run(() => this.patchResp(resp)); }); });
  }
  private patchResp(response) {
    this.router.navigate(['../edit'], { relativeTo: this.route, queryParams: { shortcode: this.shortCode } });
  }
  cancel() {
    console.log("cancel")
    this.inputURL = ' ';
    this.inputURLChange.emit(null)
  }
  showAlert() {
    this.hideAlert = false;
  }
  dismissAlert() {
    this.hideAlert = true;
  }
  alertConfirm() {
    this.dismissAlert();
  }

}
