<script type="module">
  import QrScanner from 'qr-scanner';
  import QrScannerWorkerPath from 'qr-scanner/qr-scanner-worker';
  QrScanner.WORKER_PATH = QrScannerWorkerPath
  // do something with QrScanner
</script>

<div class="container">


  <h2 class="text-center section-title">Manage</h2>


  <div class="row" [hidden]="getMenuReturnedData">
    <div class="col"></div>
    <div class="col-12 col-md-6 col-lg">

      <form #menuSelectForm="ngForm" (ngSubmit)="selectMenu(menuSelectForm)" method="post">
        <div class="form-group">
          <input id="inputCode" class="form-control" [(ngModel)]="inputShortcode" placeholder="8 Digit Code"
            name="inputCode" required>
        </div>
        <div class="form-group">
          <button class="btn btn-primary text-capitalize text-center btn-block" type='submit'>
            <i class="fa fa-search" aria-hidden="true"></i>
            Lookup Code
          </button>
        </div>
      </form>
      <button type="button" class="btn btn-primary text-capitalize text-center btn-block" [hidden]="doingScan"
        (click)="startScan()">
        <i class="fa fa-qrcode" aria-hidden="true"></i>
        Scan Code
      </button>
      <div *ngIf="doingScan === true" class="card w-100 text-center">
        <h3>Scan Code</h3>
        <select class="form-control" (change)="onDeviceSelectChange($event.target.value)">
          <option value="" [selected]="!currentDevice">No Device Selected</option>
          <option *ngFor="let device of availableDevices" [value]="device.deviceId"
            [selected]="currentDevice && device.deviceId === currentDevice.deviceId">{{device.label}}</option>
        </select>
        <zxing-scanner (scanSuccess)="scanSuccessHandler($event)" [formats]="['QR_CODE']" [(device)]="currentDevice"
          (camerasFound)="onCamerasFound($event)" (permissionResponse)="onHasPermission($event)"></zxing-scanner>
        <!-- <p *ngIf="qrlink$">{{qrlink$}}</p> -->

      </div>

    </div>
    <div class="col"></div>
  </div>

  <div class="row">
    <div class="col">
      <h4 *ngIf="getMenuErrorData" class="alert text-danger text-center">
        {{getMenuErrorData}}
      </h4>
    </div>
  </div>

  <div class="row">
    <div *ngIf="getMenuReturnedData && !getMenuErrorData" class="w-100">
      <div class="col">
        <div class="row">
          <div class="col">
            <h3 class="text-center">{{getMenuReturnedData.entityName}}</h3>
            <h4 class="text-center">{{getMenuReturnedData.shortCode}}</h4>
          </div>
        </div>

        <div class="row text-center">
          <div class="col">
            <a [hidden]="!hasUniqueLink" class="btn btn-info" href="{{uniqueLink}}" target="_blank">
              <i class="fa fa-newspaper-o" aria-hidden="true"></i>
              View
            </a>
            <a class="btn btn-warning" [routerLink]="['../upload']" [queryParams]="{shortcode: shortcode}">
              <i class="fa fa-cloud-upload" aria-hidden="true"></i>
              Update
            </a>
            <button [hidden]="localNotes === getMenuReturnedData.notes" type="button" class="btn btn-danger"
              (click)="sendNotesPatch()">
              <i class="fa fa-save" aria-hidden="true"></i>
              Save Changes
            </button>

            <!-- <button type="button" class="btn btn-secondary" (click)="reset()">
              <i class="fa fa-search" aria-hidden="true"></i>
              Edit a Different Menu
            </button> -->

          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg"></div>
          <div class="col col-lg-8">
            <label for="inputNotesField">Notes</label>
            <textarea maxlength="250" id="inputNotesField" name="inputNotesField" [(ngModel)]="localNotes"
              placeholder="" class="form-control" [ngModelOptions]="{standalone: true}" name="inputNotes"></textarea>
          </div>
          <div class="col-12 col-lg"></div>
        </div>


        <app-qrcomponent *ngIf="menuURL" [url]="menuURL" (qrGenerated)="qrGeneratedHandler($event)">
        </app-qrcomponent>



        <div *ngIf="!isPublished" class="row text-center">
          <div class="col text-center">
            <button type="button" class="btn btn-danger" (click)="publish()">
              <i class="fa fa-upload" aria-hidden="true"></i>
              Publish
            </button>
          </div>
        </div>
        <div [hidden]="hideAlert" class="fixed-top col text-center">
          <div class="alert alert-warning alert-dismissible fade show" role="alert">
            <p><strong>Are you sure?</strong></p>
            <p>Verify this redirect link matches the document you uploaded or url you entered: <a target="_blank"
                href="{{getMenuReturnedData.redirectUrl}}">{{getMenuReturnedData.redirectUrl}}</a></p>
            <button type="button" class="btn btn-primary" (click)="hideConfirm()">
              <i class="fa fa-close" aria-hidden="true"></i>
              Cancel
            </button>
            <button type="button" class="btn btn-danger" (click)="publishConfirm()">
              <i class="fa fa-check" aria-hidden="true"></i>
              Yes, Publish
            </button>
            <button type="button" class="close" (click)="hideConfirm()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>

        <div [hidden]="hideSuccessfulPublishAlert" class="fixed-top col text-center">
          <div class="alert alert-success alert-dismissible fade show" role="alert">
            <p>Success!</p>
            <button type="button" class="close" (click)="hidePublishSuccess()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-xs-12 col-sm-2 col-md-3 col-lg-2 col-xl-5"></div> -->
</div>