import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../auth/service';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router, RouterEvent, Event, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  signedInUserName: string;
  githubImagePath: string;

  isProduction = environment.production;
  sub: Subscription;

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.sub = this.router.events.pipe(
      filter((e: Event): e is RouterEvent => e instanceof NavigationStart)
    ).subscribe((e: NavigationEnd) => {
      this.authService.getCurrentUser((err, signedInUser) => {
        this.signedInUserName = signedInUser.username;
      });
    });
    //TODO subscribe to an event in the authService for user changes


  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}

