import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadService } from './service';
import { ContainerEvents, FileObject, FileObjectStatus, EntityObject } from './types';
import { AuthService, User } from '../auth';
import { URLUtil } from '../../utils';
import { NgForm } from '@angular/forms';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

/**
 * Contrainer for all uploads.
 */
@Component({
  moduleId: module.id,
  selector: 'app-upload',
  templateUrl: 'component.html',
  styleUrls: ['component.scss']
})
export class UploadContainerComponent implements OnInit {
  file: FileObject;
  //files: FileObject[] = [];
  signedInUser: User;
  uploadStarted = false;

  routeSub;
  shortcode;

  entitiesList;
  selectedEntity;
  selectedEntityKey;

  updatingMenu = false;

  inputURL;
  hideAlert = true;

  selectedTopEntity;
  visibleEntitiesList;

  config = {
    displayKey: "path", // if objects array passed which key to be displayed defaults to description

    search: true,
    //limitTo: 10,
  };


  constructor(private authService: AuthService,
    private router: Router,
    private uploadService: UploadService,
    private ngZone: NgZone,
    private http: HttpClient, public route: ActivatedRoute) {
    uploadService.fileUploadEvent$.subscribe(
      fileObject => this.handleFileUploadEvent(fileObject)
    );
  }
  private handleFileUploadEvent(fileObject: FileObject) {
    if (fileObject.status === FileObjectStatus.Deleted) {
      this.file = null;
    }
  }

  // private handleFilesUploadEvent(fileObject: FileObject) {
  //   if (fileObject.status === FileObjectStatus.Deleted) {
  //     for (let i = 0; i < this.files.length; i++) {
  //       if (this.files[i] === fileObject) {
  //         this.files.splice(i, 1);
  //       }
  //     }
  //   }
  // }
  fileChangeEvent(fileInput: any) {
    console.log(this.selectedEntity);
    if (fileInput.target.files && fileInput.target.files.length) {
      const fileObject = new FileObject(fileInput.target.files[0]);
      this.file = fileObject;

    }
  }
  // filesChangeEvent(fileInput: any) {
  //   if (fileInput.target.files && fileInput.target.files.length) {
  //     for (let i = 0; i < fileInput.target.files.length; i++) {
  //       const fileObject = new FileObject(fileInput.target.files[i]);
  //       this.files.push(fileObject);
  //     }
  //   }
  //   fileInput.target.value = null;
  // }

  uploadAll() {
    this.uploadService.publishUploadContainerEvent(ContainerEvents.Upload);
  }

  cancelAll() {
    this.uploadService.publishUploadContainerEvent(ContainerEvents.Cancel);
  }

  clearAll() {
    this.uploadService.publishUploadContainerEvent(ContainerEvents.Delete);
  }
  createEmpty() {

    if (this.updatingMenu && this.shortcode) {
      this.authService.getAccess((err, user) => {
        this.uploadService.sendPatchMenu(user, this.selectedEntity.id, this.selectedEntity.entityId, null, null, [environment.parkedUrl, this.shortcode].join('/'), null).subscribe((resp) => { this.ngZone.run(() => this.createCodeResp(resp)); });
      });
    } else {
      this.authService.getAccess((err, user) => {
        this.uploadService.createEmptyCode(user, this.selectedEntity.id).subscribe((resp) => { this.ngZone.run(() => this.createCodeResp(resp)); });
      });
    }



  }
  private createCodeResp(response) {
    console.log("create code response:\n" + JSON.stringify(response, null, 4));

    this.router.navigate(['../edit'], { relativeTo: this.route, queryParams: { shortcode: response.shortCode } });

    //this.authService.getAccess((err, user) => { this.uploadService.sendPatchMenu(user, response.id, this.entityId, null, null, this.url).subscribe((resp) => { this.ngZone.run(() => this.patchResp(resp)); }); });
  }

  ngOnInit() {
    this.routeSub = this.route.queryParams.subscribe(params => {
      if (params.shortcode) {
        this.shortcode = params.shortcode;
      }
      if (params.filter) {
        this.selectedTopEntity = params.filter;

      }
    });
    this.authService.getCurrentUser((err, user: User) => {
      this.signedInUser = user;
      this.uploadService.setSignedInUser(this.signedInUser);
      // if (!this.signedInUser || !this.signedInUser.signedIn) {
      //   // this.authService.redirectToSignin(this.router.routerState.snapshot.root.queryParams);
      //   this.router.navigate(['signin']);
      //   return;
      // } else {
      this.authService.getAccess((err, user) => {
        // if query tag for updating, get entity
        if (this.shortcode) {

          this.updatingMenu = true;
          this.getExistingMenuFromShortcode(user).subscribe((resp) => {
            this.ngZone.run(() => this.getExistingMenuFromShortcodeResp(resp));
          });
        } else { // else show list
          this.getEntitiesList(user).subscribe((resp) => {
            this.ngZone.run(() => this.entitiesListResp(resp));
          });
        }

      });
      // }
    });
    this.setRegion();
  }

  setRegion() {
    const queryParams = this.router.routerState.snapshot.root.queryParams;
    if (queryParams && queryParams.region) {
      this.uploadService.setRegion(queryParams.region);
    }
  }
  selectEntity(select) {
    console.log(select)
    this.selectedEntity = this.visibleEntitiesList[select];
  }
  selectEntityFromSearchDropdown(select) {
    console.log(select)
    this.selectedEntity = select.value;
  }
  getEntitiesList(user: CognitoIdToken): Observable<Object> {
    return this.http.get<string>(environment.baseApiUrl + '/entities/userentities', {
      headers: {
        ['Authorization']: user.getJwtToken(),
        ['Content-Type']: 'application/json'
      }
    });
  }
  private entitiesListResp(response) {
    this.entitiesList = response;
    console.log("entity list response:\n" + JSON.stringify(response, null, 4));
    if (this.selectedTopEntity) {
      this.setFilter(this.selectedTopEntity);
    } else {
      this.setFilter("All");
    }
  }
  getExistingMenuFromShortcode(user: CognitoIdToken): Observable<Object> {
    return this.http.get<string>(environment.baseApiUrl + '/menus/findbyshortcode/' + this.shortcode, {
      headers: {
        ['Authorization']: user.getJwtToken(),
        ['Content-Type']: 'application/json'
      }
    });
  }
  private getExistingMenuFromShortcodeResp(response) {
    this.selectedEntity = response;
    console.log("Menu response:\n" + JSON.stringify(response, null, 4));
  }
  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }
  inputURLChange($event) {
    console.log($event)
    this.inputURL = $event;
  }

  showAlert() {
    this.hideAlert = false;
  }
  dismissAlert() {
    this.hideAlert = true;
  }
  alertConfirm() {
    this.dismissAlert();
    this.createEmpty();
  }

  setFilter(filter) {
    this.selectedTopEntity = filter;
    console.log("change")
    this.visibleEntitiesList = [];

    if (this.selectedTopEntity === 'All') {
      this.visibleEntitiesList = [...this.entitiesList];
      console.log('all')
    } else if (this.selectedTopEntity === 'None') {
      this.visibleEntitiesList = [];
      console.log('none')
    }
    else {
      this.entitiesList.forEach(element => {
        console.log(`${element.path} | ${this.selectedTopEntity}`)
        if (element.path.split(' > ')[0] === this.selectedTopEntity) {
          this.visibleEntitiesList.push(element);
        }
      });
    }
  }


}
