import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  NgZone,
} from '@angular/core';
import { Subject, Observable, throwError } from 'rxjs';
import { AuthService } from '../auth';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { NgForm } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { environment } from '../../environments/environment';
import { UploadService } from '../upload';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditComponent implements OnInit, AfterViewInit {
  sub;
  qrlink$;
  shortcode;
  getMenuReturnedData;
  doingScan = false;
  menuURL;
  getMenuErrorData;
  inputShortcode;
  fullShortMenuURL;
  isPublished;
  qrObservable = new Subject<string>();
  hideAlert = true;
  hasUniqueLink = false;
  uniqueLink;
  localNotes;

  hasPermission: boolean;
  hasDevices: boolean;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  hideSuccessfulPublishAlert = true;
  // @ViewChild('scanner', { static: false })
  // scanner: ZXingScannerComponent;
  qrEventChange() {
    this.qrObservable.next(this.qrlink$);
  }

  constructor(
    private uploadService: UploadService,
    private auth: AuthService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.sub = this.qrObservable.subscribe((s) => {});
  }
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.shortcode) {
        this.shortcode = params.shortcode.toLowerCase();
        this.getMenuTrigger();
      } else {
        if (this.shortcode) {
          // TODO: hacky reset, should probably clear data instead
          location.reload();
        }
      }
    });
  }
  startScan() {
    this.doingScan = true;
  }
  selectMenu(selectEntity: NgForm) {
    this.shortcode = this.inputShortcode;
    this.shortcode = this.shortcode.toLowerCase();
    this.getMenuTrigger();
    selectEntity.resetForm();
  }
  scanSuccessHandler(result: string) {
    this.qrlink$ = result;
    let newCode = result.substr(result.length - 8, 8);
    if (this.shortcode != newCode) {
      this.shortcode = newCode;
      this.getMenuTrigger();
    }
  }
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }
  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find((x) => x.deviceId === selected);
    this.currentDevice = device || null;
    console.log('changed device selection: ' + device);
  }
  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  getMenuTrigger() {
    this.menuURL = null;
    // this.doingScan = false;
    this.getMenuErrorData = null;
    this.auth.getAccess((err, user) => {
      this.getMenu(user).subscribe((resp) => {
        this.ngZone.run(() => this.getMenuResp(resp));
      });
    });
  }
  getMenu(user: CognitoIdToken): Observable<Object> {
    console.log(user);
    //return this.http.get<string>('https://jc43ylesp1.execute-api.us-west-2.amazonaws.com/dev/menu', {
    return this.http
      .get<string>(
        environment.baseApiUrl + '/menus/findbyshortcode/' + this.shortcode,
        {
          headers: {
            ['Authorization']: user.getJwtToken(),
            ['Content-Type']: 'application/json',
          },
        }
      )
      .pipe(
        catchError((err) => this.handleError(err)) // then handle the error
      );
  }
  private getMenuResp(response) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { shortcode: this.shortcode },
      queryParamsHandling: 'merge',
    });

    this.doingScan = false;
    this.getMenuReturnedData = response;
    console.log(this.getMenuReturnedData);
    this.menuURL = this.shortcode.toLowerCase();
    this.isPublished = this.getMenuReturnedData.isPublished === 1;
    console.log(this.isPublished);
    this.fullShortMenuURL = [environment.shortUrl, this.shortcode].join('/');
    if (
      !this.getMenuReturnedData.redirectUrl.includes(environment.redirectUrl)
    ) {
      this.hasUniqueLink = true;
      this.uniqueLink = this.getMenuReturnedData.redirectUrl;
    }
    this.localNotes = this.getMenuReturnedData.notes;
  }
  qrGeneratedHandler(event) {}

  private handleError(error: HttpErrorResponse) {
    this.getMenuErrorData = JSON.stringify(error, null, 4);
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status) {
        this.getMenuErrorData = 'Invalid code';
      }
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  reset() {
    this.shortcode = null;
    this.menuURL = null;
    this.doingScan = false;
    this.getMenuErrorData = null;
    this.getMenuReturnedData = null;
    this.isPublished = false;
  }
  publish() {
    this.hideAlert = false;
  }
  hideConfirm() {
    this.hideAlert = true;
  }
  publishConfirm() {
    this.hideConfirm();
    this.auth.getAccess((err, user) => {
      this.sendPublishMenuPatch(user, this.getMenuReturnedData.id).subscribe(
        (resp) => {
          this.ngZone.run(() => this.patchResp(resp));
        }
      );
    });
  }
  sendPublishMenuPatch(user: CognitoIdToken, menuId): Observable<Object> {
    // const body = {
    //   "entityId": this.entity.id,
    //   "filename": filename,
    //   "bucketPath": bucketPath,
    //   "redirectUrl": redirectUrl
    // }

    return this.http
      .patch<string>(
        environment.baseApiUrl + '/menus/publish/' + menuId,
        null,
        {
          headers: {
            ['Authorization']: user.getJwtToken(),
            ['Content-Type']: 'application/json',
          },
        }
      )
      .pipe(
        catchError((err) => this.handleError(err)) // then handle the error
      );
  }
  private patchNotesResp(response) {
    console.log(
      `Remembered state: ${this.isPublished}, returned state: ${
        response.isPublished === 1
      }`
    );
    if (this.isPublished) {
      console.log(`Sending publish menu patch`);
      //if published, we need to re-publish since it got unpublished due to our patch
      this.auth.getAccess((err, user) => {
        this.sendPublishMenuPatch(user, this.getMenuReturnedData.id).subscribe(
          (resp) => {
            this.ngZone.run(() => this.patchResp(resp));
          }
        );
      });
    } else {
      console.log(`Continuing to patch response`);
      this.patchResp(response);
    }
  }
  private patchResp(response, shouldRepublish = false) {
    if (!this.isPublished) {
      this.isPublished = response.isPublished === 1;
      if (this.isPublished) {
        this.showPublishedAlert();
      }
    }

    this.getMenuReturnedData.notes = response.notes;
    this.getMenuReturnedData.isPublished = response.isPublished;
    this.localNotes = this.getMenuReturnedData.notes;
  }
  sendNotesPatch() {
    this.auth.getAccess((err, user) => {
      this.uploadService
        .sendPatchMenu(
          user,
          this.getMenuReturnedData.id,
          this.getMenuReturnedData.entityId,
          this.getMenuReturnedData.filename,
          this.getMenuReturnedData.bucketPath,
          this.getMenuReturnedData.redirectUrl,
          this.localNotes
        )
        .subscribe((resp) => {
          this.ngZone.run(() => this.patchNotesResp(resp));
        });
    });
  }
  showPublishedAlert() {
    this.hideSuccessfulPublishAlert = false;
  }
  hidePublishSuccess() {
    this.hideSuccessfulPublishAlert = true;
  }
}
