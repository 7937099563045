import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitter } from 'protractor';
import { GeneratedQRObject } from './types';
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss']
})
export class BatchComponent implements OnInit {
  urls;
  file;
  fr;
  qrCodes: GeneratedQRObject[] = [];
  zip = new JSZip();


  constructor(
    private router: Router) {
  }
  qrGeneratedHandler(qr: GeneratedQRObject) {
    this.qrCodes.push(qr);
    JSZipUtils.getBinaryContent(qr.base64, (err, data) => {
      this.zip.file(qr.filename, data, { binary: true });
    });
  }
  handleDownloadAll() {

    this.zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'qrcodes.zip');
    });
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files.length) {
      // const fileObject = new FileObject(fileInput.target.files[0]);
      this.file = fileInput.target.files[0];
    }
  }
  handleFile() {
    this.fr = new FileReader();
    this.fr.onload = this.receivedText;
    this.fr.readAsText(this.file);
  }
  receivedText = () => {
    console.log(this.fr.result);
    // this.urls = JSON.parse(this.fr.result).urls;
    this.urls = this.fr.result.split(', ');
  }



  ngOnInit() {

  }

}
